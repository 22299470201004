/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useState} from 'react';
import {
    Button,
    Flex,
    FlexItem,
    Tooltip,
    Divider,
    Popover,
    Badge,
    Spinner,
    Bullseye,
    CardBody,
    DescriptionListDescription,
    DescriptionListTerm,
    DescriptionListGroup,
    DescriptionList, Text, TextVariants, TextContent
} from '@patternfly/react-core';
import {KaravanApi} from "../api/KaravanApi";
import '../designer/karavan.css';
import Icon from "./Logo";
import '../designer/smh-karavan.css';
import UserIcon from "@patternfly/react-icons/dist/js/icons/user-icon";
import ProjectsIcon from "@patternfly/react-icons/dist/js/icons/repository-icon";
import ProcessedEntitiesIcon from "@patternfly/react-icons/dist/js/icons/th-list-icon";
import ProductMilestonesIcon from "@patternfly/react-icons/dist/js/icons/flag-icon";
import TemplatesIcon from "@patternfly/react-icons/dist/js/icons/blueprint-icon";
import KnowledgebaseIcon from "@patternfly/react-icons/dist/js/icons/book-open-icon";
import ContainersIcon from "@patternfly/react-icons/dist/js/icons/cubes-icon";
import ConfigIcon from "@patternfly/react-icons/dist/js/icons/cogs-icon";
import LicenseIcon from "@patternfly/react-icons/dist/js/icons/key-icon";
import ServicesIcon from "@patternfly/react-icons/dist/js/icons/services-icon";
import {useAppConfigStore, useDevModeStore, useFileStore} from "../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {useNavigate} from "react-router-dom";
import {useLicenseStore} from '../api/LicenseStore';
import {LicenseService} from '../api/LicenseService';
import {SsoApi} from "../api/SsoApi";
import {useSmhIntegrationStore} from "../api/SmhIntegrationStore";

class MenuItem {
    pageId: string = '';
    tooltip: string = '';
    icon: any;

    constructor(pageId: string, tooltip: string, icon: any) {
        this.pageId = pageId;
        this.tooltip = tooltip;
        this.icon = icon;
    }
}

export function PageNavigation () {

    const [config, loading] = useAppConfigStore((state) => [state.config, state.loading], shallow)
    const [setFile] = useFileStore((state) => [state.setFile], shallow)
    const [setStatus, setPodName] = useDevModeStore((state) => [state.setStatus, state.setPodName], shallow)
    const [showUser, setShowUser] = useState<boolean>(false);
    const [pageId, setPageId] = useState<string>();
    const navigate = useNavigate();
    const {license} = useLicenseStore();
    const {integrationsCount} = useSmhIntegrationStore();

    function isLicenseValid() {
        return license !== undefined
            && license != null
            && !license.expired
            && license.appCode !== undefined
            && license.appCode !== ''
            && license.integrationLimit >= integrationsCount;
    }

    function getMenu() : MenuItem[]  {
        const pages: MenuItem[] = [];

        if(isLicenseValid()) {
            pages.push(
                // new MenuItem("dashboard", "Dashboard", <DashboardIcon/>),
                new MenuItem("projects", "Projects", <ProjectsIcon/>),
                new MenuItem("processed-entities", "Processed Entities", <ProcessedEntitiesIcon/>),
                new MenuItem("product-milestones", "Product Milestones", <ProductMilestonesIcon/>),
                new MenuItem("templates", "Templates", <TemplatesIcon/>),
            )

            if (config.infrastructure === 'docker') {
                pages.push(
                    new MenuItem("services", "Services", <ServicesIcon/>),
                    new MenuItem("containers", "Containers", <ContainersIcon/>)
                )
            }
            pages.push(
                new MenuItem("knowledgebase", "Knowledgebase", <KnowledgebaseIcon/>),
            );
            pages.push(
                new MenuItem("configuration", "Configuration", <ConfigIcon/>)
            );
        }

        pages.push(
            new MenuItem("license", "License Management", <LicenseIcon/>)
        );

        return pages;
    }

    return (<Flex className="nav-buttons" direction={{default: "column"}} style={{height: "100%"}}
                  spaceItems={{default: "spaceItemsNone"}}>
        <FlexItem className="logo-container" alignSelf={{default: "alignSelfCenter"}}>
            <Bullseye>
                {loading && <Spinner style={{position: "absolute"}} diameter="40px" aria-label="Loading..."/>}
                <Tooltip className="logo-tooltip" content={config.title}
                         position={"right"}>
                    {Icon()}
                </Tooltip>
            </Bullseye>

        </FlexItem>
        {getMenu().map(page =>
            <FlexItem key={page.pageId} className={pageId === page.pageId ? "nav-button-selected" : ""}>
                <Tooltip content={page.tooltip} position={"right"}>
                    <Button id={page.pageId} icon={page.icon} variant={"plain"}
                            className={pageId === page.pageId ? "nav-button-selected" : ""}
                            onClick={async (event) => {
                                setFile('none', undefined);
                                setPodName(undefined);
                                setStatus("none");
                                setPageId(page.pageId);

                                if(page.pageId === 'license') {
                                    await LicenseService.getLicense()
                                        .then(res => {
                                            const [err, _license] = res;
                                            if(err == null && _license != null) {
                                                useLicenseStore.setState({license: _license!!, operation: "none"})
                                            }
                                        });
                                }

                                navigate(page.pageId);
                            }}
                    />
                </Tooltip>
            </FlexItem>
        )}
        <FlexItem flex={{default: "flex_2"}} alignSelf={{default: "alignSelfCenter"}}>
            <Divider/>
        </FlexItem>
        {KaravanApi.authType !== 'public' &&
            <FlexItem alignSelf={{default: "alignSelfCenter"}}>
                <Popover
                    hasAutoWidth
                    aria-label="Current user"
                    position={"right-end"}
                    hideOnOutsideClick={false}
                    isVisible={showUser}
                    shouldClose={(_event, tip) => setShowUser(false)}
                    shouldOpen={(_event, tip) => setShowUser(true)}
                    headerContent={
                        <TextContent>
                            <Text component={TextVariants.h3}>Profile</Text>
                        </TextContent>
                    }
                    bodyContent={
                        <CardBody>
                            {KaravanApi.me?.roles && Array.isArray(KaravanApi.me?.roles) &&
                                <DescriptionList
                                    isHorizontal
                                    orientation={{
                                        md: 'vertical',
                                        lg: 'horizontal',
                                        xl: 'vertical',
                                        '2xl': 'horizontal'
                                    }}
                                >
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Username</DescriptionListTerm>
                                        <DescriptionListDescription>
                                            <Badge isRead>{KaravanApi.me?.userName}</Badge>
                                        </DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>First Name</DescriptionListTerm>
                                        <DescriptionListDescription>
                                            <Badge isRead>{SsoApi.keycloakUserProfile?.firstName}</Badge>
                                        </DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Last Name</DescriptionListTerm>
                                        <DescriptionListDescription>
                                            <Badge isRead>{SsoApi.keycloakUserProfile?.lastName}</Badge>
                                        </DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Roles</DescriptionListTerm>
                                        <DescriptionListDescription>
                                            {
                                                KaravanApi.me?.roles
                                                    .filter((r: string) => ['administrator', 'developer', 'viewer'].includes(r))
                                                    .map((role: string) => <Badge key={role} id={role} style={{backgroundColor: "#e6ffe6"}} isRead>{role}</Badge>)
                                            }
                                        </DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm><a href={`${SsoApi.keycloak?.authServerUrl}/realms/karavan/account/#/personal-info`} target="_blank">Profile</a></DescriptionListTerm>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm><a href={`${SsoApi.keycloak?.authServerUrl}/realms/karavan/protocol/openid-connect/logout?client_id=${SsoApi.keycloak!!.clientId!!}&id_token_hint=${encodeURIComponent(SsoApi.keycloak!!.idToken!!)}&post_logout_redirect_uri=${window.location.href}`}>Logout</a></DescriptionListTerm>
                                    </DescriptionListGroup>
                                </DescriptionList>
                            }
                        </CardBody>
                    }
                >
                    <UserIcon className="avatar"/>
                </Popover>
            </FlexItem>}
    </Flex>)
}